import findLastIndex from 'lodash/findLastIndex';

import CartStore, { productsForOrder, ProductsRemovedFromCart } from '../store/cart';
import { HasShownMaxQuantityStore } from '../store/maxQuantity';
import { OfferSelected, OfferModal, SelectionMade } from '../store/offers';

import { addToAnalyticsGenericEvent, getProductInfo, addToCart, removeFromCart } from './analytics';
import { offerLink } from './products';

export const MAX_CART_VOLUME = 600;

function onAddProductsUpdate(productQuantity, productAdded) {
  const currentProducts = productsForOrder.getStorageValue();
  const orderProducts = currentProducts || [];

  if (productQuantity === 0) {
    productsForOrder.update([productAdded, ...orderProducts]);
  } else {
    productsForOrder.update([...orderProducts, productAdded]);
  }
}

function addCombinedOffer(offer, quantity = 0, productLimit = 6) {
  if (offer?.groups?.length && quantity < productLimit) {
    if (SelectionMade.getValue() || quantity !== 0) {
      const newOffer = { ...offer, url: offerLink(offer) };
      onAddProductsUpdate(quantity, newOffer);
      OfferModal.update(false);
      SelectionMade.update(false);
      return;
    }
    OfferSelected.update(offer);
    OfferModal.update(true);
  }

  if (offer?.products?.length && quantity < productLimit) {
    const newOffer = { ...offer, url: offerLink(offer) };
    onAddProductsUpdate(quantity, newOffer);
    OfferSelected.update(offer);
  }
}

function addRegularProduct(product, isProductMYO, configDefaultOption, quantity = 0, productLimit = 6) {
  if (product.stepToUom * quantity < productLimit) {
    if (!isProductMYO) {
      onAddProductsUpdate(quantity, product);
      return;
    }
    const productWithOptions = product.options ? product : { ...product, options: [configDefaultOption] };

    onAddProductsUpdate(quantity, productWithOptions);
  }
}

export function onClickAddToOrder(
  product,
  productLimit,
  configDefaultOption,
  analyticsOptions,
  quantity = 0,
  isProductMYO = false
) {
  if (quantity === productLimit - 1) {
    addToAnalyticsGenericEvent('maxItemsAllowed');
  }
  addToCart(getProductInfo(product), analyticsOptions);
  addCombinedOffer(product, quantity, productLimit);
  addRegularProduct(product, isProductMYO, configDefaultOption, quantity, productLimit);
}

export function onClickRemoveItem(product) {
  removeFromCart(getProductInfo(product));
  const currProducts = productsForOrder.getStorageValue() || [];
  const tempProducts = currProducts.filter(p => p._id !== product._id);
  productsForOrder.update(tempProducts);
}

export function onClickRemoveFromOrder(product, analyticsOptions) {
  removeFromCart(getProductInfo(product), analyticsOptions);
  const tempProducts = productsForOrder.getStorageValue();
  const idx = findLastIndex(tempProducts, p => p._id === product._id);
  const removedProducts = tempProducts.filter((p, i) => i !== idx);
  productsForOrder.update(removedProducts);
}

export function clearCart() {
  const productsForOrderValue = productsForOrder.getStorageValue() || [];
  productsForOrderValue.forEach(product => {
    removeFromCart(getProductInfo(product));
  });
  productsForOrder.update([]);
  ProductsRemovedFromCart.update([]);
  CartStore.setIsVisible(false);
  HasShownMaxQuantityStore.clear();
}

export function getCampaignsDiscount(campaign, productsCart, Order, totalPrice) {
  const productsInCampaign = productsCart.filter(p => p.tags && p.tags.includes(campaign.tag));
  if (!productsInCampaign.length) return 0;
  const sumOfProducts = productsInCampaign.reduce((sum, p) => sum + p.finalPrice * p.stepToUom, 0);

  const isEligble = sumOfProducts >= campaign.threshold.value;
  if (!isEligble) return 0;
  const order = Order.getValue();
  const campaignApplied = order?.discountCampaignsApplied
    ? [...order.discountCampaignsApplied, campaign._id]
    : [campaign._id];
  const uniqueCampaignsApplied = [...new Set(campaignApplied)];

  if (campaign.discount.type === 'percentage') {
    const discount =
      campaign.discount.appliesOn === 'tagged'
        ? sumOfProducts * campaign.discount.value
        : totalPrice * campaign.discount.value;
    Order.setDiscountCampaignsApplied(uniqueCampaignsApplied);

    return discount;
  }
  if (campaign.discount.type === 'absolute_value') {
    const discount =
      campaign.discount.appliesOn === 'tagged'
        ? sumOfProducts - campaign.discount.value
        : totalPrice - campaign.discount.value;

    Order.setDiscountCampaignsApplied(campaignApplied);
    return discount;
  }
}

export default {
  onClickAddToOrder,
  onClickRemoveFromOrder,
  onClickRemoveItem,
  clearCart,
  MAX_CART_VOLUME,
};
